<template>
    <div class="content-wrapper">
        <div class="page-header page-header-sticky page-header-sticky-open">
            <div class="page-header-content page-header-dark mb-0 header-elements-md-inline">

                <div class="page-header-info flex-1 py-3 text-uppercase">
                    <div class="d-flex justify-content-between">
                        <div>
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="phi-main font-weight-semibold mb-2 pb-1">
                                    <span>{{row.ap_fullname||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No Rekam Medis">{{row.ap_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left">{{rowReg.ar_reg_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No KTP">{{row.ap_nik||"-"}}</span>
                                </h5>
                            </div>
                            <div class="phi-sub-info d-flex">
                                <div class="phi-info d-flex flex-column">
                                    <small>Tanggal Lahir</small>
                                    <strong class="font-weight-semibold">{{row.ap_dob | moment("DD MMM YYYY")}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Usia</small>
                                    <strong class="font-weight-semibold">{{row.ap_usia_with_ket || "-"}}<br />
                                    ({{row.ap_gol_usia||"-"}})</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Jenis Kelamin</small>
                                    <strong class="font-weight-semibold">{{row.cg_label}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Opsi Pembayaran</small>
                                    <strong class="font-weight-semibold">{{rowReg.mcp_name || "-"}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Agama</small>
                                    <strong class="font-weight-semibold">{{row.ap_agama_text || "-"}}</strong>
                                </div>
                            </div>
                        </div>
                        <div class="phi-info d-flex flex-column ml-auto">
                            <small>Dokter</small>
                            <h5 class="phi-main font-weight-semibold mb-0">
                                <span>{{rowReg.bu_full_name||"-"}}</span>
                            </h5>
                            <div class="mb-2">
                                <strong class="font-weight-semibold">{{rowReg.mpo_name||"-"}}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a href="javascript:;" class="btn btn-sm btn-primary page-header-sticky-toggle">
                <i class="icon-arrow-up5 align-middle"></i>
                <span class="align-middle">Sembunyikan Informasi Pasien</span>
            </a>
        </div>
        
        <div class="content">
            <div class="card">
                <div class="card-header">
                    <div class="d-flex justify-content-between align-items-center">
                        <h5 class="card-title font-weight-semibold">Riwayat HAIs</h5>
                    </div>
                </div>
                <table class="table table-bordered table-sm patient-table">
                    <thead>
                        <tr>
                            <th>No. Reg</th>
                            <th>Perawat</th>
                            <th>Tanggal</th>
                            <th>Kategori</th>
                            <th>Kejadian</th>
                            <th>Aksi</th>
                        </tr>
                    </thead>
                    <tbody v-for="(v,k) in (riwayatHais||[])" :key="k+'reg'">
                        <tr v-for="(v1,k1) in (v.data||[])" :key="k1+'regData'">
                            <td v-if="k1 == 0" :rowspan="v.rowspan">
                                <a class="font-weight-semibold border-bottom">{{v.ar_reg_code}}</a>
                            </td>
                            <td>
                                <a class="font-weight-semibold border-bottom">{{v1.bu_full_name||"-"}}</a>
                            </td>
                            <td>{{v1.aha_created_date | moment("DD MMM YYYY")}}</td>
                            <td><span class="text-danger font-weight-semibold">{{v1.mh_name||"-"}}</span></td>
                            <td>{{getKejadian(v1.aha_kejadian)||"-"}}</td>
                            <td v-if="k1 == 0" :rowspan="v.rowspan">
                                <a href="javascript:;" @click="getDetailHais(v.ar_id)" data-toggle="modal" data-target="#DetailHais" class="btn alpha-primary border-primary text-primary-800 btn-icon rounded-round" data-popup="tooltip" v-b-tooltip.hover title="Lihat Detail"><i class="icon-file-eye"></i></a>

                                <a href="javascript:;" @click="downloadReport(v.ar_id)" class="btn alpha-success border-success text-success-800 btn-icon rounded-round" data-popup="tooltip" v-b-tooltip.hover title="Unduh Laporan HAIs"><i class="icon-download"></i></a>
                                
                                <router-link class="btn alpha-info border-info text-info-800 btn-icon rounded-round"
                                    v-if="$parent.moduleRole('edit')"
                                    :to="{name: $route.name, params: {pageSlug: v.ar_id}}"
                                    data-toggle="tooltip" data-placement="top" v-b-tooltip.hover :title="'Edit HAIS'"><i
                                class="icon-pencil"></i></router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <b-modal v-model="openTindakan" :title="'Riwayat Hais'" size="xl" hide-footer>
                <template v-if="(dataHais||[]).length">
                <div class="modal-header d-block">
                    <ul class="nav nav-tabs nav-tabs-bottom mb-0">
                    <li v-for="(v,k) in (dataHais||[])" :key="k+'hais'" class="nav-item"><a href="javascript:;" :class="activeTabHais == (k+1) ? 'nav-link active' : 'nav-link'" data-toggle="tab" data-target="#tabHais1" @click="changeTabHais(k+1)">Kejadian #{{k+1}}</a></li>
                    </ul>
                </div>
                <div class="modal-body">
                    <div class="tab-content">
                        <div v-for="(v,k) in (dataHais||[])" :key="k+'hais2'" 
                        :class="activeTabHais == (k+1) ? 'tab-pane fade show active': 'tab-pane fade'" id="tabHais1">
                            <div class="table-responsive">
                            <table class="table table-bordered">
                                <tbody class="detail_bo">
                                <tr>
                                    <td>
                                    <div class="result_tab">
                                        <h4>No. Reg</h4>
                                        <p>{{v.ar_reg_code||"-"}}</p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Perawat</h4>
                                        <p>{{v.bu_full_name||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Tanggal</h4>
                                        <p>{{v.aha_created_date | moment("DD MMM YYYY")}}</p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Kategori</h4>
                                        <p> {{v.mh_name||"-"}}
                                        </p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                    <div class="result_tab">
                                        <h4>Komponen</h4>
                                        <ul>
                                        <li v-for="(v1,k1) in (v.aha_komponen||[])" :key="k1">{{v1||"-"}}</li>
                                        </ul>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                    <div class="result_tab">
                                        <table class="table table-bordered  table-sm patient-table">
                                        <thead>
                                            <tr>
                                            <th>Kejadian</th>
                                            <th>Catatan</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="(v2,k2) in (v.aha_kejadian||[])" >
                                                <tr v-if="v2.value" :key="k2">
                                                <td>{{v2.name||"-"}}</td>
                                                <td>{{v2.val_name||"-"}}</td>
                                                </tr>
                                            </template>
                                        </tbody>
                                        </table>
                                    </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                </div>
                </template>
                <span v-else class="mt-3 d-block">Tidak ada Data Hais</span>
            </b-modal>
            <!-- /dashboard content -->
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')

export default{
    props:{
        row:Object,
        rowReg:Object,
        mrValidation:Object,
        Config: Object,
        dataHais: Array,
        mHais: Array,
        riwayatHais: Array
    },
    
    mounted() {    
        setTimeout(()=>{
            this.initSticky()
        },1000)
    },
    data() {
        return {
            openTindakan: false,
            activeTabHais: 1
        }
    },
    methods: {
        changeTabHais(e){
            this.activeTabHais = e
        },
        getDetailHais(id){
            this.$parent.loadingOverlay = true
            Gen.apiRest(
                "/do/"+this.$parent.modulePage,
                {data: {id: id, type: "get-data-hais"}}, 
                "POST"
            ).then(res=>{
                this.$parent.loadingOverlay = false
                this.openTindakan = true
                this.$parent.dataHais = res.data.dataHais
            }).catch(()=>{
                this.$parent.loadingOverlay = false
            })
        },
        getKejadian(data){
            let newArr = []
            for(let i = 0; i < (data||[]).length; i++){
                if(data[i]['value']){
                    newArr.push(data[i]['name'])
                }
            }
            return newArr.join(", ")
        },
        initSticky(){
            const PageHeaderSticky = document.querySelector('.page-header-sticky')
            if(PageHeaderSticky){    
                const PageHeaderToggle = PageHeaderSticky.querySelector('.page-header-sticky-toggle')
                if($(".page-header-sticky").length && $(".page-header-sticky-toggle").length){
                    PageHeaderToggle.addEventListener('click', function(e){
                    // e.preventDefault()
                    PageHeaderSticky.classList.toggle('page-header-sticky-open')
                    if (PageHeaderSticky.classList.contains('page-header-sticky-open')) {
                        PageHeaderToggle.querySelector('span').innerText = 'Sembunyikan Informasi Pasien'
                        PageHeaderToggle.querySelector('i').classList.add('icon-arrow-up5')
                        PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-down5')
                    }else{
                        PageHeaderToggle.querySelector('span').innerText = 'Tampilkan Informasi Pasien'
                        PageHeaderToggle.querySelector('i').classList.add('icon-arrow-down5')
                        PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-up5')
                    }
                    })    
                } 
            }
        },
        downloadReport(ar_id){
            let data = {exptype: 'xlsx', type: "export", id: ar_id}
            let self = this

            $.ajax({
                type: "POST",
                url: process.env.VUE_APP_API_URL + `/report/${'hais'}?token=IXs1029102asoaksoas102901290`,
                data: data,
                cache: false,
                xhrFields:{
                    responseType: 'blob'
                },
                success: data => 
                {
                    self.loadingOverlay = false
                    var link = document.createElement('a')
                    link.href = window.URL.createObjectURL(data)
                    link.download = `Hais-${moment().format("YYYY-MM-DD")}.xlsx`
                    link.click()
                },
                fail: data => {
                    self.loadingOverlay = false
                    alert('Not downloaded')
                }
            })
        }
    }
}
</script>
